.App {
  text-align: center;
  /* background-color: #ede9ce; */
  height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.header-contain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: #935347; */
}

.welcome-header {
  font-size: 4rem;
  height: 6rem;
  margin: 1rem;
  padding: 0;
}

.card {
  margin: 1rem;
  height: 10rem;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-body,
.login-form {
  background-color: #f0eceb;
}

.card-text {
  font-size: 2rem;
}

.card-link {
  border: 2px solid black;
  padding: 0.5rem 2rem;
  color: black;
}

.card-link:hover {
  color: black;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 8% auto;
  justify-content: space-around;
  align-items: center;
  line-height: 1.5;
  border-radius: 20px;
  font-weight: 400;
  height: 20rem;
}

.login-text {
  font-size: 2rem;
}

.login-field {
  margin-bottom: 1rem;
}

.submit {
  margin: 1rem;
  background-color: #729f98;
}
