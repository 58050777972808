@import "~antd/dist/antd.css";

.nav-bar {
  background-color: #24292e;
  color: #fff;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;

  h1 {
    color: #fff;
  }

  nav {
    font-size: 1rem;
    font-weight: 600;
    width: 40%;
    display: flex;
    justify-content: center;
    justify-content: space-evenly;

    .link {
      color: #fff;
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: 0.3s;
      margin-right: 1rem;
    }
    .link:hover {
      color: #767777;
    }
  }
}
