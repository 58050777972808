@import "../../App.scss";

.search {
  width: 25%;
  margin: 2rem auto;
}

.all-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.available-items-container {
  display: flex;
  width: 20%;
  margin: 2rem;
}
