@import "../../App.scss";

.border-line {
  margin-top: 3rem;
  border: 1px solid #e8e8e8;
}

.items-main-container {
  display: flex;
}

.lend-items {
  width: 50%;
  height: 100vh;
}

.borrowed-items {
  height: 100vh;
  width: 50%;
  border-left: 1px solid #e8e8e8;
}

.dashboard-header, .dashboard-button {
  margin-top: 1rem;
}

.lend-item-button {
  background-color: #283018;
  border-color: #283018;
}

.lend-item-button:hover{
  background-color: #aa863a;
  border-color: #aa863a;
}

.lend-items h2, .borrowed-items h2 {
  margin-top: 2rem;
}
